:root {
  --body-max-width: 100%;
  --sidebar-width: 15%;
}
.wrapper {
  margin: 0;
  width: 100%;
  background-color: #ecf0f5;
}
.main-sidebar {
  width: 100%;
  background-color: #222d32;
  li {
    padding: 10px 20px;
  }
  a,
  i.fa {
    color: #b8c7ce;
  }

}
.sidebar-menu>li>.treeview-menu {
  background-color: #2c3b41;
}

.sidebar-menu li:not(.submenu-active).active .fa,
.sidebar-menu li:not(.submenu-active).active a {
  color: #ffffff;
}

body.easyadmin-sidebar-width-compact {
  .main-sidebar {
    width: 65px;
  }
  .wrapper {
    grid-template-columns: 65px auto;
    .info-box {
      display: block;
      min-height: 90px;
      background: #fff;
      width: 100%;
      box-shadow: 0 1px 1px rgba(0,0,0,0.1);
      border-radius: 2px;
      margin-bottom: 15px;
      .bg-aqua, .callout.callout-info, .alert-info, .label-info, .modal-info .modal-body {
        background-color: #00c0ef !important;
      }
    }
  }
}

header {
  .user.user-menu-wrapper {
    background-color: #ffffff;
    padding: 10px 25px;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    color: #000;
  }
  .logo-long {
    padding: 10px;
    display: block;
  }
}

.wrapper {

  .info-box {
    display: block;
    min-height: 90px;
    background: #fff;
    width: 100%;
    box-shadow: 0 1px 1px rgba(0,0,0,0.1);
    border-radius: 2px;
    margin-bottom: 15px;
    i {
      font-size: 45px;
      text-align: center;
    }
    .info-box-icon {
      display: inline-block;
      padding: 20px;
      float: left;
      color: #fff;
    }
    .bg-aqua, .callout.callout-info, .alert-info, .label-info, .modal-info .modal-body {
      background-color: #00c0ef !important;
    }
    .bg-red, .callout.callout-danger, .alert-danger, .alert-error, .label-danger, .modal-danger .modal-body {
      background-color: #dd4b39 !important;
    }
    .bg-yellow, .callout.callout-warning, .alert-warning, .label-warning, .modal-warning .modal-body {
      background-color: #f39c12 !important;
    }
    .bg-green, .callout.callout-success, .alert-success, .label-success, .modal-success .modal-body {
      background-color: #00a65a !important;
    }
    .info-box-content {
      padding: 20px 25px;
      margin-left: 90px;
      .info-box-number {
        font-weight: 600;
        font-size: 25px;
      }
    }
  }
  .content-wrapper {
    margin-bottom: 35px;
    .content {
      padding: 20px;
      h1 {
        font-size: 18px;
      }
      h2 {
        font-size: 17px;
      }
      h3 {
        font-size: 16px;
      }
      .field-checkbox .form-check-label {
        float: left;
        width: auto;
      }
    }
  }
}