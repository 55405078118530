@import "~@fortawesome/fontawesome-free/scss/fontawesome";
@import '~@fortawesome/fontawesome-free/scss/regular';
@import '~@fortawesome/fontawesome-free/scss/solid';
@import '~@fortawesome/fontawesome-free/scss/brands';
@import "~bootstrap/scss/bootstrap";

/**
COLORS
 */
$blue-careden: #3597d4;
$blue-dark-careden: #298BC7;
$blue-dark-extra-careden: #2C3E50;
$red-careden: #E83B3B;
$grey-careden: #2C3E50;
$grey-light: #F8F8F8;
$grey-medium: #EFEFEF;
$green-careden: #009900;

/**
GENERAL
 */
body{
  font-size: 16px;
  font-family: 'Montserrat', sans-serif;
  h2 {
    font-size: 40px;
    font-weight: 500;
    span {
      color: $blue-careden;
    }
  }
  .text-blue {
    color: $blue-careden;
  }
  .text-black {
    color: #000;
  }
  .img-responsive {
    max-width: 100%;
  }
  .bg-gray-light {
    background-color: $grey-light;
  }
  span.status-code {
    color: $blue-dark-careden;
    display: inline-block;
    font-size: 120px;
    border: 5px solid $blue-dark-careden;
    border-radius: 50%;
    margin-top: 60px;
    font-weight: 600;
    padding: 45px;
  }
}

/**
HEADER
 */
header {
  background-color: $blue-careden;
  position: relative;
  z-index: 10;
  .header-logo {
    max-width: 250px;
  }
  #user-info {
    .btn-white {
      background-color: #fff;
      color: $blue-careden;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      text-transform: uppercase;
      border: none;
    }
  }
  .breadcrumb-container {
    padding: 260px;
    background-color: #ffffff;
    background-image: url("../images/bc-top.png"), url("../images/bc-utilisateurs.png");
    background-position: top, bottom;
    background-size: 100%;
    background-repeat: no-repeat;
    .tree {
      a {
        color: #ffffff;
        text-decoration: none;
      }
    }
    &#breadcrumb-careden {
      background-image: url("../images/bc-top.png"), url("../images/bc-careden.png");
    }
    &#breadcrumb-carrossiers {
      background-image: url("../images/bc-top.png"), url("../images/bc-carrossiers.png");
    }
    &#breadcrumb-utilisateurs {
      background-image: url("../images/bc-top.png"), url("../images/bc-utilisateurs.png");
    }
    &#breadcrumb-contact {
      background-image: url("../images/bc-top.png"), url("../images/bc-contact.png");
    }
  }
}

.home-header-container {
  background-image: url("../images/bg-header-home.png");
  color: #fff;
  background-size: cover;
  background-position: bottom;
  padding-bottom: 0;
  margin-bottom: -100px;
  .negative-marge {
    margin-bottom: -70px;
    margin-top: -80px;
  }
}


/**
MENU GLOBAL
 */
nav {
  &#main-menu {
    &.navbar {
      background-color: transparent;
      ul {
        li {
          &.active,
          &:hover {
            a {
              border-bottom: 5px solid rgba(255,255,255,0.5);
              transition: ease all 0.5s;
            }
          }
          a {
            transition: ease all 0.5s;
            color: #fff;
            text-transform: uppercase;
            margin: 10px 15px;
            padding-bottom: 0px;
            line-height: 15px;
            font-size: 14px;
            border-bottom: 5px solid transparent;
            font-weight: 500;
          }
        }
      }
    }
  }
}

/**
BTN GLOBAL
 */
button,
a {
  &.btn-white-blue {
    background-color: #fff;
    color: $blue-careden;
    text-transform: uppercase;
    border: 1px solid $blue-careden;
    &:hover {
      background-color: $blue-careden;
      color: #fff;
      border: 1px solid #fff;
    }
  }
  &.btn-white-red {
    background-color: #fff;
    color: $red-careden;
    text-transform: uppercase;
    border: 1px solid $red-careden;
    &:hover {
      background-color: $red-careden;
      color: #fff;
    }
  }
  &.btn-red {
    background-color: $red-careden;
    color: #fff;
    text-transform: uppercase;
    border: 1px solid $red-careden;
    &:hover {
      background-color: #fff;
      color: $red-careden;
    }
  }
  &.btn-blue {
    background-color: $blue-careden;
    color: #fff;
    border: 1px solid $blue-careden;
    &:hover {
      background-color: $blue-careden;
      color: #fff;
    }
  }
  &.btn-green {
    background-color: $green-careden;
    color: #fff;
    border: 1px solid $green-careden;
    &:hover {
      background-color: $green-careden;
      color: #fff;
    }
  }
  &.btn-grey {
    background-color: $grey-careden;
    color: #fff;
    border: 1px solid $grey-careden;
    &:hover {
      background-color: #fff;
      color: $grey-careden;
    }
  }
  &.btn-red, &.btn-white-red, &.btn-white-blue, &.btn-blue, &.btn-grey, &.btn-green {
    border-radius: 25px;
    padding: 7px 20px;
    transition: 0.5s ease all;
    display: inline-block;
    margin-bottom: 10px;
    font-weight: 500;
    span {
      font-size: 12px;
    }
    &:hover {
      transition: 0.5s ease all;
      text-decoration: none;
    }
  }
  &.btn-big {
    border-radius: 60px;
    padding: 7px 50px;
  }
}

/**
CONTENT
 */
ol.ol-counter {
  counter-reset: item;
  list-style-type: none;
  padding-left: 0;
  li {
    display: block;
    padding: 15px 0;
    &:hover {
      cursor: pointer;
    }
    &:before {
      font-size: 30px;
      font-weight: 600;
      content: counter(item, decimal-leading-zero) ". ";
      counter-increment: item;
    }
    &.active:before,
    &:hover:before {;
      color: $red-careden;
    }
  }
}

ul#ul-carrossiers {
  padding-left: 0;
  li {
    list-style: none;
    margin-bottom: 25px;
    display: inline-block;
    width: 100%;
    span {
      font-size: 16px;
    }
  }
}

#news_container {
  padding: 250px 0 330px;
  background-image: url("../images/bg_actu_top.jpg"), url("../images/bg_actu_bottom.jpg");
  background-position: top, bottom;
  background-size: contain;
  background-color: $grey-careden;
  background-repeat: no-repeat;
  .title-container {
    position: relative;
    float: left;
    .post-date {
      position: relative;
      transform: rotate(-90deg);
      display: inline-block;
      left: -20px;
      font-weight: 600;
      top: 45px;
    }
    h3 {
      font-size: 24px;
    }
  }
}

.registration_form .jumbotron,
.estimation-process .jumbotron,
.account .jumbotron,
.account.jumbotron{
  background-color: #3597d4;
  color: #fff;
  float: left;
}

.account .list-group-item { color: #000; }

form sup {
  color: red;
  font-weight: 600;
  font-size: 15px;
  padding: 5px;
}

.minus-margin {
  margin-bottom: -200px;
}

/**
FORM
 */
form {
  label {
    width: 100%;
  }
  .form-group {
    display: inline-block;
    input,
    textarea {
      border: none;
      border-radius: 60px;
      margin: 10px;
      background-color: #ffffff;
      padding: 7px 20px;
      float: left;
    }
    textarea {
      min-height: 250px;
      border-radius: 15px;
    }
    &.grey-form {
      input,
      textarea {
        background-color: $grey-medium;
        width: 100%;
      }
    }
  }
  &.input-m0 {
    input,
    textarea {
      margin: 0;
    }
  }
}

table {
  &.table-dark {
    td {
      &.green {
        background-color: #28a745;
        color: #000; }
      &.red {
        background-color: #dc3545;
        color: #000; }
    }
  }
  &#table_prices {
    input.form-control {
      width: 80%;
      float: left;
    }
    span.currency {
      width: 20%;
      float: left;
      line-height: 38px;
      text-align: center;
      font-weight: 600;
      font-size: 20px;
    }
  }
}

#repairmanDashboard {
  .price_container {
    th {
      text-align: center;
      vertical-align: middle;
      font-weight: 400;
    }
    td.price {
      font-size: 25px;
      font-weight: 600;
    }
  }
}

.card-body {
  color: #000000;
}

/**
FOOTER
 */
footer {
  background-image: url("../images/bg-footer.png");
  background-size: cover;
  padding-top: 250px;
  #block-newsletter {
    .text-white {
       font-size: 14px;
    }
    .text-extra-blue {
      font-size: 30px;
      font-weight: 600;
      color: $blue-dark-extra-careden;
    }
  }

  nav {
    &#main-menu {
      &.navbar {
        ul {
          li {
            &.active,
            &:hover {
              a {
                border-bottom: none;
              }
            }
          }
        }
      }
    }
  }
  #legacy-links {
    background-color: $blue-dark-careden;
    padding: 15px;
    a {
      color: rgba(255,255,255,0.4);
      font-size: 12px;
      font-weight: 500;
      &:hover {
        color: #fff;
        text-decoration: none;
        transition: 0.5s ease all;
      }
    }
  }
}

/**
EFFECT
 */
.effect-1 {
  position: relative;
  margin-bottom: 80px;
  &::before {
    content: "";
    position: absolute;
    bottom: -50px;
    left: 50%;
    background-color: $blue-careden;
    height: 50px;
    width: 2px;
  }
  &::after {
    content: "";
    position: absolute;
    bottom: -64px;
    left: calc(50% - 7px);
    border: 2px solid $blue-careden;
    height: 16px;
    width: 16px;
    border-radius: 10px;
  }
}

.effect-2 {
  position: relative;
  float: left;
  margin-bottom: 80px;
  &::after {
    content: " ";
    width: 80px;
    height: 80px;
    display: block;
    position: absolute;
    z-index: -1;
    background-color: $blue-careden;
    top: calc(100% + -150px);
    left: calc(100% - 160px);
  }
}

/**
ESTIMATION PAGE
 */

#estimationForm .card-body {
  padding: 20px 0;
  a:hover {
    cursor: pointer;
  }
}

.avatar-image {
  max-height: 150px;
  width: auto;
}

/**
REGISTER
 */
.register-form label {
  text-align: left;
}
.register-form select {
  padding: 10px;
  border: none;
  border-radius: 25px;
  background-color: #fff;
}
.register-form span {
  float: left;
  width: 100%;
  text-align: left;
  padding: 15px 0;
}
.register-form .col-6 input,
.register-form .col-4 input {
  float: left;
  width: 100%;
}
.register-form .col-12 input,
.register-form .col-12 select {
  float: left;
  width: 100%;
}
.clearfix {
  float: left;
  width: 100%;
}
hr {
  float: left;
  width: 100%;
  margin-bottom: 2px;
}

#repairman3filters {
  .card-body {
    &.blue span.filter-title {
      color: #64C2CB;
      font-weight: 600;
    }
    &.expert-box span.filter-title {
      color: #9c0c0c;
      font-weight: 600;
    }
    &.red span.filter-title {
      color: #EB5561;
      font-weight: 600;
    }
    &.yellow span.filter-title {
      color: #FDCC5E;
      font-weight: 600;
    }
    &.expert-box, &.blue, &.red, &.yellow {
      .avatar-image {
        max-width: 50px;
      }
    }
  }
}

.sticky {
  position: fixed!important;
  width: 100%;
  top: 0;
  z-index: 10000;
  border-top: 0;
  margin: auto;
  background-color: #3597d4;
}

footer .navbar-toggler {
  display: none;
}

#incident_form_isActive {
  input[type="radio"],
  input[type="checkbox"],
  label {
    float: left;
    width: auto;
  }
  label { margin-right: 25px; }
  input[type="radio"],
  input[type="checkbox"] { padding: 10px;
    height: 25px;
    width: 25px;
    margin-right: 5px;  }


}

@media screen and (max-width: 1024px) {
  .navbar-toggler {
    border: 1px solid #fff;
    color: #ffffff;
  }
  div#navbarText {
    position: absolute;
    top: 50px;
    border: 1px solid #fff;
    right: 0;
    background-color: $blue-dark-extra-careden;
    padding: 15px 35px;
    z-index: 1000;
  }
  #user-info {
    position: absolute;
    right: 10px;
  }
  header .header-logo { max-height: 85px; }
  header .breadcrumb-container { padding: 160px; }
  footer { padding-top: 150px; }

}

@media screen and (max-width: 1024px) {
  .minus-margin { margin-bottom: 0; }
}

@media screen and (max-width: 768px) {
  .home-header-container .negative-marge {
      margin-bottom: 0;
      margin-top: 0;
  }
  .home-header-container {
    margin-bottom: 0;
  }
  header .breadcrumb-container { padding: 90px; }
  footer { padding-top: 100px; }
  form .form-group input, form .form-group textarea { margin: 0; margin-bottom: 10px; }
}

@media screen and (max-width: 425px) {
  header .breadcrumb-container { padding: 40px; }
  footer { padding-top: 50px; }
}


